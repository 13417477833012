import {Hint, HorizontalGridLines, LineMarkSeries, XAxis, XYPlot, YAxis} from "react-vis";
import React, {useEffect, useState} from "react";
import {AutoSizer} from "react-virtualized";
import {format} from "d3-format";
import DateFormatter from "../../utils/formatters/DateFormatter";

interface Props {
    data: any[],
    colour: string,
    title: string,
    formatValue: (value: string) => {},
    yAxisFormat: (value: any) => string
}

const StockMarketLineSeriesChart: React.FC<Props> = (props: Props) => {

    const [hover, setHover] = useState<any>();

    return (
        <AutoSizer>
            {({ height, width }) => (
                <XYPlot width={width}
                        margin={{left: 60}}
                        height={210}
                        >


                    <HorizontalGridLines />
                    <LineMarkSeries
                        getNull={(d) => d.y != null}  //only draw if different than null
                        color={props.colour}
                        data={props.data}
                        onValueMouseOver={(v: any) => {
                            setHover(v);
                        }}
                        onValueMouseOut={(v: any) => {
                            setHover(null);
                        }} />

                    <XAxis tickFormat={tick => DateFormatter.formatMillis(tick)} title="Date" tickValues={props.data.map(item => item.x)} />
                    <YAxis tickFormat={tick => '$' + tick} title={props.title} />

                    {hover && (
                        <Hint value={hover}>
                            <div className="chart-hint">
                                <div className="x">{DateFormatter.formatMillis(hover.x)}</div>
                                <div className="y">{(props.formatValue(hover.y) as String)?.replace(/G/, "B")}</div>
                            </div>
                        </Hint>
                    )}
                </XYPlot>
            )}
        </AutoSizer>
    );
};

export default StockMarketLineSeriesChart;
